<template>
	<article class="layer" style="display:block">
		<div class="layer__dim"></div>
		<!-- [D] 세로 가운데 정렬 레이어 일 경우 layer__wrap--centered 클래스 추가 -->
		<div class="layer__wrap layer__wrap--feed">
			<div class="layer-feed">
				<div class="layer-feed__top">
					<button type="button" @click="handleClose">
						<i class="icon icon-close--white"></i>
					</button>
				</div>
				<div class="layer-feed__header">
					<h3 class="layer-feed__title">
						{{t('10662')}}
					</h3>
					<div class="layer-feed__category">
						<!-- [D] 활성회 되었을때 active 클래스 추가 -->
						<a href="#" class="active">
							숙소
							<span class="layer-feed__badge">{{mfd0103.length}}</span>
						</a>
						<!--
						<span class="layer-feed__dot"></span>
						<a href="#">
							레저티켓
							<span class="layer-feed__badge">27</span>
						</a>
						-->
					</div>
					<div class="layer-feed__slider">
						<div class="item__body">
							<div class="feed-no" v-if="mfd0103.length === 0">
								<!-- [D] 숙소, 레저티켓 없을 경우 -->
								<div class="box box--reverse">
									{{t('10026')}}
								</div>
							</div>
							<div class="carousel carousel--left" v-else>
								<!-- [REST] 지역별 숙소 호출 -->
								<div class="carousel__slider">
									<swiper v-if="mfd0103.length > 0" :scrollbar="{ el : '.progress__bar--1' }">
										<swiper-slide v-for="item in mfd0103" :key="item.HOTEL_NO">
											<router-link :to="`/rooms/${item.HOTEL_NO}`" class="carousel__link">
												<div class="carousel__image noneimage">
													<div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL + '\')'}" />
												</div>
												<div class="carousel__info">
													<div class="carousel__slogan info-slogan">
														{{item.HOTEL_DESC}}
													</div>
													<h3 class="carousel__title info-title">
														{{item.HOTEL_NM}}
													</h3>
													<div class="carousel__grad info-grad" v-if="item.REVIEWS > 0">
														<span class="carousel__icon info-icon">
															<i class="icon icon-star"></i>
														</span>
															<span class="carousel__gradnum info-gradnum">
															{{item.SCORE.toFixed(1)}}
														</span>
														<span class="carousel__gradcounter info-gradcounter">
															({{item.REVIEWS}})
														</span>
													</div>
													<div class="carousel__tag info-tag">
														{{item.HOTEL_DESC2}}
													</div>
													<div class="carousel__price">
														<span class="list__badge">숙박</span>
														<span class="carousel__saleprice" v-if="item.DCRATE_NIGHT > 0">
															{{item.DCRATE_NIGHT}}%
														</span>
														<span class="carousel__currentprice">
															<strong>{{item.SALESAMT_NIGHT.toLocaleString("en-US")}}</strong>원
														</span>
													</div>
												</div>
											</router-link>
										</swiper-slide>
									</swiper>
									<div class="progress">
										<div class="progress__bar progress__bar--1"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
import { computed, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n' 

import i18n from "@/i18n"; 
export default {
	props: ["handleClose", "feedNo"],
	setup(props) {
		const store = useStore();
		const { t }= useI18n() 
		onMounted(() => {
			store.dispatch("mfd01/fetchMfd0103", {
				feed_no: props.feedNo
			})
		});

		return {
			mfd0103: computed(() => store.state.mfd01.mfd0103[props.feedNo] || []),
			 t,  //번역필수 모듈
		  i18n, 
		}
	},
}
</script>